<template>
    <div style="height: 100%;min-with:100%" v-loading="loading">
        <iframe :src="url" frameborder="0" id="i4" style="width: 100%;height:100%"></iframe>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    // 在网站内打开外部链接
    name: 'ExternalLink',
    data () {
        return {
            show: true,
            loading: false,
            url: ''
        }
    },

    computed: {
        ...mapState('user', ['userInfo'])
    },

    created () {
        this.setUrl()
    },

    beforeRouteUpdate (to, from, next) {
        next()
        this.setUrl()
    },

    mounted () {
    },

    methods: {
        setUrl () {
            this.url = this.$route.query.url
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
